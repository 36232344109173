import { post } from '@/utils/request'

// 单位列表
export function getList(data) {
    return post('/admin/unit/unitList', data)
}
// 添加单位
export function addUnit(data) {
    return post('/admin/unit/addUnit', data)
}
// 单位回显
export function getInfo(data) {
    return post('/admin/unit/getUnit', data)
}
// 编辑单位
export function editUnit(data) {
    return post('/admin/unit/editUnit', data)
}
// 删除单位
export function delUnit(data) {
    return post('/admin/unit/delUnit', data)
}
// 获取全部单位
export function allUnit(data) {
    return post('/admin/unit/allUnit', data)
}
